.footer-div {
  margin-top: 1rem;  /* Reduzido para melhor ajuste em mobile */
  background-color: rgb(29, 29, 29);
  color: #ffffff;
  padding: 2rem;  /* Reduzido para melhor ajuste em mobile */
  position: relative;

}
.footer-line{
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 2px;
  background-color: #CCED31;
  animation: correr 2.5s ease-in-out  infinite ;
}
@keyframes correr{
  
  0%{
    transform: translateX(-100%);
  }
  100%{
    transform: translateX(calc(100vw + 45%));
  }
  
}
.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;  /* Reduzido para melhor ajuste em mobile */
  
}

.footer-content h4 {
  margin-bottom: 0.5rem;  /* Reduzido para melhor ajuste em mobile */
  text-align: center;
  font-size: 1.2rem; /* Reduzir o tamanho da fonte para telas menores */
}

.footer-section {
  flex: 1;
  margin: 0.5rem;  /* Reduzido para melhor ajuste em mobile */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-section h4 {
  margin-bottom: 0.5rem;  /* Reduzido para melhor ajuste em mobile */
  text-align: center;
  font-size: 1.2rem; /* Ajustado para telas menores */
}

.footer-menu ul,
.footer-contact ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-menu ul li,
.footer-contact ul li {
  margin: 0;
  padding: 0.3rem 0; /* Reduzir o espaçamento entre os itens em mobile */
}

.footer-section ul li a,
.footer-contact ul li a {
  color: #CCED31;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 1.2rem; /* Reduzir o tamanho da fonte para telas menores */
}

.footer-section ul li a:hover,
.footer-contact ul li a:hover {
  color: #CCED31;
}

.footer-zaask .zaask-logo {
  width: 50px; /* Ajustado para telas menores */
  height: auto;
  transition: transform 0.3s;
}

 .zaask-logo:hover {
  transform: scale(1.1);
}

.footer-text {
  text-align: center;
  font-weight: bold;
  margin-top: 1rem;  /* Reduzido para melhor ajuste em mobile */
  text-decoration: none;
  font-size: 0.9rem; /* Reduzir o tamanho da fonte para o texto de direitos autorais */
  letter-spacing: 0.05em;
}

.footer-section ul {
  text-decoration: none;
  list-style-type: none;
  margin-block: 0 !important;
  padding-inline-start: 0;
}

.footer-section ul li {
  margin: 0;
  padding: 0;
  max-height: 25px;  /* Reduzido para melhor ajuste em mobile */
}

.footer-section ul li a {
  text-decoration: none;
  color: white;
}

.footer-menu ul li a.current-link {
  position: relative;
  font-weight: bolder;
  margin: 0;
}

.footer-menu ul li a.current-link::after {
  content: "";
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  height: 0.05em;
  bottom: 0;
  width: 100%;
  background-color: #CCED31;
  transition: all ease-in-out 280ms;
}

.footer-menu ul li a:hover::after {
  width: 100%;
}

.footer-section ul li:hover {
  transform: scale(1.05);
  transition: transform 0.3s;
}

span {
  font-size: 1.15rem; /* Ajustado para telas menores */
  font-weight: bold;
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }

  .footer-section {
    align-items: flex-start;
    text-align: left;
  }

  .footer-text {
    font-size: 0.9rem;
  }
}
@media (max-width: 768px) {
  .footer-content {
    padding-bottom: 60px;
  }

}