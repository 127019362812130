.main {
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
  margin: 0px auto;


}



.greet-main {
  max-width: 100%;
  padding: 0px 150px;
  height: 100vh;
  display: flex;
  background: #EFEFBB;
  background: -webkit-linear-gradient(to right, #D4D3DD, #EFEFBB);
  background: linear-gradient(to right, #D4D3DD, #EFEFBB);
  justify-content: center;
  align-items: center;

}

.greeting-main {
  display: flex;
  /* text-align: center; */
}

.greeting-main>* {
  /* flex: 1; */
  margin-bottom: 0px;
}

.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  color: #1D1D1D;
  margin-top: 110px;
  font-size: 70px;
  font-family: "Google Sans Bold";
}

.greeting-nickname {
  font-size: 30px;
  line-height: 0px;
  font-family: "Google Sans Bold Italic";
}

.greeting-text-div {

  margin-bottom: 40px;
  display: inline-flex;
  line-height: 4rem;
  /* margin-right: 40px; */
  font-family: "Google Sans Medium";
}

.greeting-text-static {
  color: #1D1D1D;
  margin-right: 5px;
  margin-bottom: 20px;
}

.greeting-image-div>* {
  max-width: 90%;
  height: auto;
}

.greeting-image-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  justify-content: right;
}

.greeting-hand-wave {
  width: 70px;
  height: auto;
}

/* CSS */
.button2 {
  align-items: center;
  appearance: none;
  background-color: #CCED31;
  border-radius: 1rem;
  border-width: 0;
  box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.548);
  box-sizing: border-box;
  color: #1D1D1D;
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding: 1.18em 1.32em 1.03em;
  font-family: "Google Sans Regular", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  position: relative;
  text-decoration: none;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  transition: ease-in-out .2s;
  overflow: hidden;
  z-index: 2;
}

.button2::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 0%;
  background-color: #1D1D1D;
  transition: ease-in-out .3s;
  z-index: -1;
}

.button2:hover {
  transform: translateY(-5px);
  color: #CCED31;
  background-color: #1d1d1d00;
  box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.692);

}

.button2:hover::before {
  height: 100%;
}

.phone {
  color: #CCED31;
}



/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }

  .greeting-hand-wave {
    width: 50px;
  }

  .greeting-text-div {

    margin-bottom: 20px;
    /* text-align: center; */
  }

  .greet-main {
    max-width: 900px;
  }
}

@media (max-width: 768px) {
  .button2 {
    font-size: 14px;
  }

  .greeting-text {
    font-size: 42px;
    margin-top: 20px;
    text-align: center;
  }

  .greeting-text-container {
    text-align: center;
  }

  .greeting-hand-wave {
    width: 30px;
  }

  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }

  .greeting-text-div {
    font-size: 20px !important;
    margin-right: 0px;
    line-height: normal;
    margin-bottom: 20px;
    /* text-align: center; */
  }

  .greeting-main {
    /* display: block; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90vw;

  }

  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .main {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    margin: 0px auto;

  }

  .greeting-image-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
  }



}