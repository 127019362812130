body {
  overflow-x: hidden;
  background: #1D1D1D !important;
}

::selection {
  background-color: #b6d109;
  color: white;
}

