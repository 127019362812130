
.header-container-light {
  position: sticky;
  inset: 0;
  background-color: #fee9f2;
  z-index: 10;
  box-shadow: 0 2px 2px rgb(230 230 230);
}

.header-container-dark {
  position: sticky;
  inset: 0;
  background-color: #1d1d1d;
  z-index: 10;
  box-shadow: 0 2px 2px rgb(230 230 230);
}

/* Styles for the desktop menu */
.desktop-menu {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #171515;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 15px;
  border-radius: 30px;
  box-shadow: 5px -5px 20px #0400008d;
  z-index: 100;
  width: fit-content;
}

.desktop-menu-item {
  position: relative;
  color: #fff;
  text-decoration: none;
  padding: 25px 20px;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;

  z-index: 1;
  overflow: hidden;
  font-weight: bold;
}

.desktop-menu-item.active {
  background-color: #CCED31;
  border-radius: 20px;
  padding: 5px 10px;
  color: black;
  font-weight: bold;
}
/* .desktop-menu-item:hover{
  font-size: 15px;
  transition: ease-in-out .2s;
}
 */
/*  .desktop-menu-item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 15px;
  height: 2px;
  background-color: #b6d109;
  width: 0;
  transition: width 0.3s ease;
}
.desktop-menu-item:hover::after {
  width: 100%;
  animation: heartbeat116 1.5s infinite;
} */
.desktop-menu-item::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #CCED31;
  bottom: 20px;
  left: 0;
  transition: width 0.4s;
  border-radius: 10px;
}
.desktop-menu-item:hover::before {
  width: 100%;
}

@keyframes heartbeat116 {
  0%, 100% {
    width: 100%;
  }
  50% {
    width: 60%;
  }
}


.desktop-menu-logo {
  position: relative;
  top: -20px;
  background-color: #171515f4;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 -10px 10px -5px #b6d1091c;
}

.desktop-logo-image {
  width: 50px;
  height: 50px;
}

/* Styles for the mobile menu */
.mobile-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #171515;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 10px;
  box-shadow: 0 -2px 10px #b6d1091c;
  z-index: 100;
}

.mobile-menu-item {
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
  flex: 1;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
}

.mobile-menu-item.active {
  background-color: #CCED31;
  border-radius: 20px;
  padding: 5px 10px;
}

.mobile-menu-logo {
  position: relative;
  top: -35px;
  background-color: #171515;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 -10px 10px -5px #b6d1091c;
}

.mobile-logo-image {
  width: 40px;
  height: 40px;
}

/* Add some padding to the body to prevent footer overlap */
body {
  padding-bottom: 80px;
}

@media (max-width:361px){
  .mobile-menu-item{
    font-size: 12px;
    padding: 5px 8px;
  }

}