/* Estilos para a Primeira Secção */
.hero-section {
    background-image: url("../../assets/images/8527.png"); /* Mantém o background original */
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
}
ul{
    list-style: none;
}

.hero-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
}

.hero-text {
    flex: 1;
    padding-right: 20px;
    line-height: 40px;
    color: black;
}

.hero-text h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.cta-button {
    align-items: center;
    appearance: none;
    background-color: #CCED31;
    border-radius: 1rem;
    border-width: 0;
    box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.548);
    box-sizing: border-box;
    color: #1D1D1D;
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding: 1.18em 1.32em 1.03em;
    font-family: "Google Sans Regular", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    position: relative;
    text-decoration: none;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 3px;
    transition: ease-in-out .2s;
    overflow: hidden;
    z-index: 2;
}
.cta-button::before{
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 0%;
    background-color: #1D1D1D;
    transition: ease-in-out .3s;
    z-index: -1;
  }
  
  .cta-button:hover{
    transform: translateY(-5px);
    color: #CCED31;
    background-color: #1d1d1d00;
    box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.692);
    
  }
  .cta-button:hover::before{
    height: 100%;
  }

.hero-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image img {
    max-width: 100%;
    height: auto;
}

/* Responsividade para a Primeira Secção */
@media (max-width: 768px) {
    .hero-content {
        flex-direction: column;
        text-align: center;
    }

    .hero-text {
        padding-right: 0;
    }

    .hero-text h1 {
        font-size: 2em;
    }

    .hero-image {
        margin-top: 20px;
    }
}

/* Estilos para a Segunda Secção */
/* Estilos para a Segunda Secção */
.section-two {
    background-image: url("../../assets/images/8524.png");
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.section-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
}

.section-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-image img {
    max-width: 100%;
    height: auto;
}

.section-text {
    flex: 1;
    padding-left: 20px;
    color: white;
    text-align: right;
}

.section-text h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: right;
    line-height: 50px;
}

.section-text p, .section-text ul {
    font-size: 1.2em;
    line-height: 1.5;
}

/* Responsividade para a Segunda Secção */
@media (max-width: 768px) {
    .section-content {
        flex-direction: column;
        text-align: center;
    }

    .section-image {
        order: 1;
        margin-bottom: 20px;
    }

    .section-text {
        padding-left: 0;
        order: 2;
    }

    .section-text h2, .section-text p, .section-text ul {
        text-align: center;
        padding: 0;
    }
}


/* Estilos para a Terceira Secção */
.section-three {
    background: #ECE9E6;
    background: -webkit-linear-gradient(to left, #FFFFFF, #cfcbc7);
    background: linear-gradient(to left, #FFFFFF, #cfcbc7);
    padding: 60px 20px;
}

.section-three-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.section-three-image {
    flex: 1;
    text-align: center;
}

.section-three-image img {
    max-width: 100%;
    height: auto;
}

.section-three-right {
    flex: 1;
    padding-left: 20px;
    color: black;
    text-align: right;

}

.section-three-right h3 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: black;
    line-height: 50px; /* Line-height conforme a secção 3 */
}

.section-three-right h4 {
    margin-bottom: 20px;
    color: black;
    line-height: 50px; /* Line-height conforme a secção 3 */
}

.section-three-right p {
    font-size: 0.9em;
    line-height: 1.6;
    color: black;
}

/* Responsividade para a Terceira Secção */
@media (max-width: 768px) {
    .section-three-content {
        flex-direction: column;
        text-align: center;
    }

    .section-three-right {
        text-align: center;
        padding-left: 0;
    }

    .section-three-image {
        margin-top: 20px;
    }
}

/* Estilos para a Quarta Secção */
.section-four {
    background: #ECE9E6;
    background: -webkit-linear-gradient(to left, #FFFFFF, #cfcbc7);
    background: linear-gradient(to left, #FFFFFF, #cfcbc7);
    padding: 60px 20px;
}

.section-four-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.section-four-left {
    flex: 1;
    text-align: left;
    color: black;
}

.section-four-left h3 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: black;
    line-height: 50px; /* Mantido o line-height da secção 4 */
}

.section-four-left h4 {
    margin-bottom: 20px;
    color: black;
    line-height: 50px; /* Mantido o line-height da secção 4 */
}

.section-four-left p {
    font-size: 0.9em;
    line-height: 1.6;
    color: black;
}

.section-four-image {
    flex: 1;
    text-align: center;
}

.section-four-image img {
    max-width: 100%;
    height: auto;
}

/* Responsividade para a Quarta Secção */
@media (max-width: 768px) {
    .section-four-content {
        flex-direction: column;
        text-align: center;
    }

    .section-four-left {
        text-align: center;
    }

    .section-four-image {
        margin-top: 20px;
    }
}

/* Estilos para a Quinta Secção */
.section-five {
    background: #ECE9E6;
    background: -webkit-linear-gradient(to left, #FFFFFF, #cfcbc7);
    background: linear-gradient(to left, #FFFFFF, #cfcbc7);
    padding: 60px 20px;
}

.section-five-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.section-five-right {
    flex: 1;
    text-align: left;
    color: black;
}

.section-five-right h3 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: black;
    line-height: 50px; /* Line-height conforme o design anterior */
    text-align: right;
}

.section-five-right h4 {
    margin-bottom: 20px;
    color: black;
    line-height: 50px; /* Mantido o line-height */
    text-align: right;
}

.section-five-right p {
    font-size: 0.9em;
    line-height: 1.6;
    color: black;
    text-align: right;
}

.section-five-image {
    flex: 1;
    text-align: center;
}

.section-five-image img {
    max-width: 100%;
    height: auto;
}

/* Responsividade para a Quinta Secção */
@media (max-width: 768px) {
    .section-five-content {
        flex-direction: column;
        text-align: center;
    }

    .section-five-right {
        text-align: center;
    }

    .section-five-image {
        margin-top: 20px;
    }
    .section-seo-left{
        margin-left: 0 !important;
    }
    h4{
        line-height: 20px !important;
    }
}
.form-section {
    background: -webkit-linear-gradient(to left, #FFFFFF, #cfcbc7);
    background: linear-gradient(to left, #FFFFFF, #cfcbc7);
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

#hubspot-form {
    width: 100%;
    max-width: 600px;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

#hubspot-form input, #hubspot-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

#hubspot-form input::placeholder, #hubspot-form textarea::placeholder {
    font-size: 0.9em;
}

#hubspot-form button {
    background-color: #CCED31;
    color: black;
    padding: 10px 20px;
    border: none;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    width: 100%;
}

/* Responsividade */
@media (max-width: 768px) {
    .form-section {
        padding: 20px;
    }
    #hubspot-form {
        width: 90%;
    }
}
/* Nova Secção: Solução Personalizada */
.section-new {
    background: #ECE9E6;
    background: -webkit-linear-gradient(to left, #FFFFFF, #cfcbc7);
    background: linear-gradient(to left, #FFFFFF, #cfcbc7);
    padding: 60px 20px;
}

.section-new-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.section-new-left {
    flex: 1;
    text-align: left;
    color: black;
}

.section-new-left h3 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: black;
    line-height: 50px; /* Mantendo o line-height consistente */
}

.section-new-left h4 {
    margin-bottom: 20px;
    color: black;
    line-height: 50px; /* Mantendo o line-height consistente */
}

.section-new-left p {
    font-size: 0.9em;
    line-height: 1.6;
    color: black;
}

.section-new-image {
    flex: 1;
    text-align: center;
}

.section-new-image img {
    max-width: 100%;
    height: auto;
}

/* Responsividade para a Nova Secção */
@media (max-width: 768px) {
    .section-new-content {
        flex-direction: column;
        text-align: center;
    }

    .section-new-left {
        text-align: center;
    }

    .section-new-image {
        margin-top: 20px;
    }
}
/* Estilos para a Primeira Secção */
.hero-section {
    background-image: url("../../assets/images/8527.png");
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
}

.hero-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
}

.hero-text {
    flex: 1;
    padding-right: 20px;
    line-height: 40px;
    color: black;
}

.hero-text h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.cta-button {
    background-color: #CCED31;
    color: black;
    padding: 10px 20px;
    border: none;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}

.hero-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image img {
    max-width: 100%;
    height: auto;
}

/* Responsividade para a Primeira Secção */
@media (max-width: 768px) {
    .hero-content {
        flex-direction: column;
        text-align: center;
    }

    .hero-text {
        padding-right: 0;
    }

    .hero-text h1 {
        font-size: 2em;
    }

    .hero-image {
        margin-top: 20px;
    }
}

/* Estilos para a Secção de Pagamentos Seguros */
.section-payments {
    background: #ECE9E6;
    background: -webkit-linear-gradient(to left, #FFFFFF, #cfcbc7);
    background: linear-gradient(to left, #FFFFFF, #cfcbc7);
    padding: 60px 20px;
}

.section-payments-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.section-payments-left {
    flex: 1;
    text-align: left;
    color: black;
}

.section-payments-left h3 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: black;
    line-height: 50px;
}

.section-payments-left h4 {
    margin-bottom: 20px;
    color: black;
    line-height: 50px;
}

.section-payments-left p {
    font-size: 0.9em;
    line-height: 1.6;
    color: black;
}

.section-payments-image {
    flex: 1;
    text-align: center;
}

.section-payments-image img {
    max-width: 100%;
    height: auto;
}

/* Estilos para a Secção de Suporte Técnico */
.section-support {
    background: #ECE9E6;
    background: -webkit-linear-gradient(to left, #FFFFFF, #cfcbc7);
    background: linear-gradient(to left, #FFFFFF, #cfcbc7);
    padding: 60px 20px;
}

.section-support-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.section-support-right {
    flex: 1;
    text-align: left;
    color: black;
}

.section-support-right h3 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: black;
    line-height: 50px;
}

.section-support-right h4 {
    margin-bottom: 20px;
    color: black;
    line-height: 50px;
}

.section-support-right p {
    font-size: 0.9em;
    line-height: 1.6;
    color: black;
}

.section-support-image {
    flex: 1;
    text-align: center;
}

.section-support-image img {
    max-width: 70%;
    height: auto;
}

/* Estilos para a Secção de Otimização para SEO */
.section-seo {
    background: #ECE9E6;
    background: -webkit-linear-gradient(to left, #FFFFFF, #cfcbc7);
    background: linear-gradient(to left, #FFFFFF, #cfcbc7);
    padding: 60px 20px;
}

.section-seo-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.section-seo-left {
    flex: 1;
    text-align: left;
    color: black;
    margin-left: 50px;
}

.section-seo-left h3 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: black;
    line-height: 50px;
}

.section-seo-left h4 {
    margin-bottom: 20px;
    color: black;
    line-height: 50px;
}

.section-seo-left p {
    font-size: 0.9em;
    line-height: 1.6;
    color: black;
}

.section-seo-image {
    flex: 1;
    text-align: center;
}

.section-seo-image img {
    max-width: 100%;
    height: auto;
}

/* Responsividade para as Secções de Pagamentos e Suporte */
@media (max-width: 768px) {
    .section-payments-content, .section-support-content, .section-seo-content {
        flex-direction: column;
        text-align: center;
    }

    .section-payments-left, .section-support-right, .section-seo-left {
        text-align: center;
    }

    .section-payments-image, .section-support-image, .section-seo-image {
        margin-top: 20px;
    }
}
/* Secção Nova: Criação de Loja */
.section-create-store {
    background: url("../../assets/images/8524.png"); /* Background igual ao da secção "Porque precisa de uma loja online" */
    background-size: cover;
    background-position: center;
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-create-store-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
}

.section-create-store-left {
    flex: 1;
    color: white;
    text-align: left;
}

.section-create-store-left h3 {
    font-size: 1.8em;
    margin-bottom: 20px;
    line-height: 1.5;
}

.section-create-store-left p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.section-create-store-image {
    flex: 1;
    text-align: center;
}

.section-create-store-image img {
    max-width: 100%;
    height: auto;
}

.cta-button {
    background-color: #CCED31;
    color: black;
    padding: 10px 20px;
    border: none;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}

/* Responsividade */
@media (max-width: 768px) {
    .section-create-store-content {
        flex-direction: column;
        text-align: center;
    }

    .section-create-store-left {
        text-align: center;
    }

    .section-create-store-image {
        margin-top: 20px;
    }
}
/* Responsividade para as Secções */
@media (max-width: 768px) {
    /* Para a Terceira Secção */
    .section-three-content {
        flex-direction: column;
        text-align: center;
    }

    .section-three-image {
        order: 1;
        margin-bottom: 20px;
    }

    .section-three-right {
        order: 2;
        text-align: center;
        padding-left: 0;
    }

    /* Para a Quarta Secção */
    .section-four-content {
        flex-direction: column;
        text-align: center;
    }

    .section-four-image {
        order: 1;
        margin-bottom: 20px;
    }

    .section-four-left {
        order: 2;
        text-align: center;
    }

    /* Para a Secção de SEO */
    .section-seo-content {
        flex-direction: column;
        text-align: center;
    }

    .section-seo-image {
        order: 1;
        margin-bottom: 20px;
    }

    .section-seo-left {
        order: 2;
        text-align: center;
    }

    /* Para a Secção de Pagamentos */
    .section-payments-content {
        flex-direction: column;
        text-align: center;
    }

    .section-payments-image {
        order: 1;
        margin-bottom: 20px;
    }

    .section-payments-left {
        order: 2;
        text-align: center;
    }

    /* Para a Secção de Suporte */
    .section-support-content {
        flex-direction: column;
        text-align: center;
    }

    .section-support-image {
        order: 1;
        margin-bottom: 20px;
    }

    .section-support-right {
        order: 2;
        text-align: center;
    }

    /* Para a Secção de Criação de Loja */
    .section-create-store-content {
        flex-direction: column;
        text-align: center;
    }

    .section-create-store-image {
        order: 1;
        margin-bottom: 20px;
    }

    .section-create-store-left {
        order: 2;
        text-align: center;
    }
    .section-new-content {
        flex-direction: column;
        text-align: center;
    }

    .section-new-image {
        order: 1;
        margin-bottom: 20px;
    }

    .section-new-left {
        order: 2;
        text-align: center;
    }
}
@keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .section-two {
    animation: slideIn 1s forwards;
  }
  
  .section-three {
    animation: slideInRight 1s forwards;
  }
  .section-four {
    animation: slideIn 1s forwards;
  }
  .section-new {
    animation: slideIn 1s forwards;
  }
  .section-seo {
    animation: slideIn 1s forwards;
  }
  .section-payments {
    animation: slideIn 1s forwards;
  }
  .section-support {
    animation: slideIn 1s forwards;
  }
  .section-create-store {
    animation: slideIn 1s forwards;
  }