@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.hero-section_ebook {
    background: linear-gradient(to left, #FFFFFF, #cfcbc7);
    background-size: cover;
    background-position: center;
    height: 80vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.hero-content_ebook {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
}

.hero-image_ebook {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image_ebook img {
    max-width: 100%;
    height: auto;
}

.hero-text_ebook {
    flex: 1;
    padding-left: 20px;
    color: #0b0b0b;
    text-align: left;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}

.hero-text_ebook h1 {
    font-size: 3.5em;
    margin-bottom: 20px;
    line-height: 1.2;
    margin-top: 0;
}

.hero-text_ebook h3 {
    margin-bottom: 0;
    color: #252a01;
}

.highlighted-word_ebook {
    text-transform: uppercase;
    color: #000000;
    font-size: .9em;
}

.description_ebook {
    font-size: .9em;
    line-height: 1.5;
    color: #333;
    margin-top: 20px;
    font-family: "Roboto", sans-serif;
}


/* CSS */
.button-55 {
    align-self: center;
    background-color: #d0ed14;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 4px;
    border: 4px solid #000000;
    /* Define a cor exata da borda */
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-size: 2rem;
    line-height: 23px;
    outline: none;
    padding: 1rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-family: "Oswald", sans-serif;
}

.button-55:hover {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
}

.button-55:focus {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}


.cta-button_ebook:hover {
    background-color: #8fa607;
}

/* Responsividade para Mobile e Tablet */
@media (max-width: 768px) {
    .hero-content_ebook {
        flex-direction: column;
        text-align: center;
    }

    .hero-text_ebook {
        padding-left: 0;
        padding-top: 20px;
    }

    .hero-text_ebook h1 {
        font-size: 2.2em;
    }

    .description_ebook {
        font-size: 1em;
        padding: 0 10px;
    }

    .cta-button_ebook {
        width: 80%;
        font-size: 1em;
    }
}

.second-section_ebook {
    padding: 60px 60px;
    text-align: center;
    background: linear-gradient(to left, #FFFFFF, #cfcbc7);
    max-width: 100vw;
}

.second-section_ebook h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333;
}

.three-columns_ebook {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.column_ebook {
    flex: 1;
    text-align: center;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    height: 300px;
}

.column_ebook img {
    max-width: 80px;
    height: auto;
    margin-bottom: 15px;
}

.column_ebook h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #122200;

}

.column_ebook p {
    font-size: 1em;
    line-height: 1.5;
    color: #555;
}

/* Responsividade para Mobile */
@media (max-width: 768px) {
    .three-columns_ebook {
        flex-direction: column;
    }

    .column_ebook {
        margin-bottom: 20px;
    }

    .second-section_ebook h2 {
        font-size: 2em;
    }

    .review-section_ebook h2 {
        font-size: 2em;
    }
}

.review-section_ebook {
    background: linear-gradient(to left, #FFFFFF, #cfcbc7);
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.review-section_ebook h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333;
    margin: 0;
}

.carousel {
    position: relative;
    width: 80%;
    /* Ajuste a largura para garantir centralização */
    margin: 0 auto;
    /* Centraliza o carrossel */
    overflow: hidden;

    padding: 50px 0;
    box-sizing: border-box;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    /* Centraliza todo o conteúdo do carrossel */
    align-items: center;
    /* Alinha verticalmente no centro */
}

.carousel-inner {
    display: flex;
    justify-content: center;
    /* Centraliza os itens dentro do carrossel */
    transition: transform 0.5s ease-in-out;
}

.carousel-item {
    flex: 0 0 25%;
    /* Tamanho ajustado para os itens */
    margin-right: 20px;
    /* Adiciona espaço entre os itens */
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    /* Sombra leve */
}

.carousel-item img {
    width: 80px;
    /* Reduz o tamanho da imagem */
    height: 80px;
    border-radius: 50%;
}

.carousel-item p {
    font-size: 1em;
    /* Tamanho do texto */
    font-style: italic;
    color: #333;
    margin: 10px 0;
}

.carousel-item span {
    font-size: 0.9em;
    /* Tamanho do nome */
    color: #666;
    margin-top: 5px;
    font-weight: bold;
}

/* Setas de navegação */
.prev,
.next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(45, 45, 45, 0.618);
    font-size: 34px;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
    background-color: #00000000;
}

.prev {
    left: 0px;
    /* Ajusta a posição da seta para a esquerda */
}

.next {
    right: 0px;
    /* Ajusta a posição da seta para a direita */
}

.prev:hover,
.next:hover {
    background-color: rgba(95, 95, 95, 0.093);
    border-radius: 50px;
    color: rgba(45, 45, 45, 0.962);
}

.about-section_ebook {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(to left, #FFFFFF, #cfcbc7);
}

.about-content_ebook {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 60%;
    /* Ocupa 60% da largura da página */
    padding: 20px;
}

.about-image_ebook {
    flex: 1;
    max-width: 50%;
    /* A imagem ocupa 50% da largura do cartão */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-image_ebook img {
    max-width: 100%;
    height: 200%;
    /* A imagem ocupa toda a altura do cartão */
    border-radius: 10px 0 0 10px;
    /* Bordas arredondadas */
}

.about-text_ebook {
    flex: 1;
    padding: 0 20px;
    color: #333;
    text-align: left;
}

.about-text_ebook h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #122200;
    font-family: "Oswald", sans-serif;
}

.about-text_ebook p {
    font-size: 1em;
    line-height: 1.5;
    color: #555;
    font-family: "Roboto", sans-serif;
}

.form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.form-section h2 {
    font-size: 2.5em;
    margin-bottom: 0px;
    color: #122200;
    font-family: "Oswald", sans-serif;
    line-height: 1.2;
}

/* Responsividade */
@media (max-width: 768px) {
    .about-content_ebook {
        flex-direction: column;
        width: 90%;
    }

    .about-image_ebook {
        max-width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    .about-text_ebook {
        text-align: center;
    }
}

.form-image {
    display: block;
    margin: 50px 0;
    /* Centraliza a imagem */
    width: 70px;
    /* Define a largura da imagem */
    height: auto;
    /* Mantém a proporção da altura */
}

/* Ajustes gerais para mobile e tablet */
@media (max-width: 768px) {
    .hero-section_ebook {
        height: 100vh;
    }

    /* Ajuste para a seção do herói */
    .hero-content_ebook {
        flex-direction: column;
        width: 100%;
        padding: 30px;

    }

    .hero-text_ebook {
        padding-left: 0;
        text-align: center;
    }

    .hero-text_ebook h1 {
        font-size: 2.2em;
    }

    .hero-text_ebook {
        text-align: left;
    }

    .description_ebook {
        font-size: 1em;
        padding: 0 0px;
    }

    .cta-button_ebook,
    .button-55 {
        width: 100%;
        font-size: 1.4em;
        padding: 10px 20px;
    }

    /* Ajuste para as colunas da segunda secção */
    .three-columns_ebook {
        flex-direction: column;
        gap: 20px;
    }

    .column_ebook {
        width: 100%;
        margin-bottom: 20px;
    }

    /* Ajustes para a seção de reviews */
    .review-section_ebook h2 {
        font-size: 2em;
    }

    .carousel-item {
        flex: 0 0 100%;
        /* Exibe um item por vez no carrossel em mobile */
    }

    /* Ajuste para a secção 'Quem é a Clickspot?' */
    .about-content_ebook {
        flex-direction: column;
        width: 90%;
        margin: 0 auto;
    }

    .about-image_ebook {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    .about-text_ebook {
        text-align: center;
        font-size: 1em;
    }

    /* Ajuste para o formulário e imagem */
    .form-section {
        padding: 20px;
    }

    .form-section h2 {
        font-size: 2em;
        line-height: 1.3;
        margin-bottom: 15px;
    }

    .form-image {
        margin: 10px auto;
        width: 40px;
        /* Ajusta a imagem do formulário */
    }

    /* Ajuste para botões */
    .button-55 {
        width: 90%;
        margin: 20px auto;
    }

    .about-image_ebook img {
        max-width: 60%;
    }

    .prev:hover,
    .next:hover {
        background-color: rgba(95, 95, 95, 0);
        color: rgba(45, 45, 45, 0.962);
    }

    .prev,
    .next {
        outline: none;
        /* Remove o contorno de foco padrão */
        border: none;
        /* Remove qualquer borda */
        box-shadow: none;
        /* Remove qualquer sombra de foco */
        background-color: transparent;
        /* Assegura que o fundo é transparente */
    }

    .prev:focus,
    .next:focus {
        outline: none;
        /* Remove o quadrado azul ao focar */
        box-shadow: none;
        /* Remove qualquer sombra */
    }

    .prev:active,
    .next:active {
        outline: none;
        /* Remove o contorno quando o botão é pressionado */
        border: none;
        box-shadow: none;
    }
}

/* Ajustes gerais para mobile e tablet */
@media (max-width: 768px) {

    /* Ajuste específico para o carrossel em dispositivos móveis */
    .carousel-item {
        flex: 0 0 100%;
        /* Exibe um item por vez no carrossel em mobile */
        margin-right: 0;
        /* Remove margem direita para um único item */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0)
    }

    .carousel {
        width: 100%;
        /* Garante que o carrossel ocupe 100% da largura no mobile */
        padding: 20px 0;
        /* Reduz o padding no mobile */
    }

    .carousel-inner {
        display: flex;
        transition: transform 0.5s ease-in-out;
        width: 80%;
        /* Garante que a largura se ajuste corretamente */
        overflow: hidden;
    }

    .carousel-item img {
        width: 60px;
        /* Ajusta o tamanho da imagem para telas menores */
        height: 60px;
        /* Mantém a proporção */
    }

    .carousel-item p {
        font-size: 0.9em;
        /* Ajusta o tamanho do texto para mobile */
    }

    .carousel-item span {
        font-size: 0.8em;
        /* Ajusta o tamanho do nome */
    }


    .prev,
    .next {
        font-size: 24px;
        /* Reduz o tamanho das setas em mobile */
        width: 40px;
        height: 40px;
    }

    .prev {
        left: -10px;
    }

    .next {
        right: -10px;
    }

    .review-section_ebook {
        padding: 40px;
    }
}

.carousel-item .profession {
    display: block;
    font-size: 0.8em;
    /* Tamanho menor para a profissão */
    color: #999;
    /* Cor cinza clara */
    margin-top: 2px;
    /* Espaço entre o nome e a profissão */
}

.button-55 i {
    margin-right: 8px;
    /* Espaço entre o ícone e o texto */
}

/* Estilo para mobile/tablet */
.button-55 .mobile-icon {
    display: none;
}

/* Para dispositivos móveis, esconde a seta e mostra o dedo */
@media (max-width: 768px) {
    .button-55 .desktop-icon {
        display: none;
        /* Esconde a seta */
    }

    .button-55 .mobile-icon {
        display: inline-block;
        /* Mostra o dedo */
        margin-right: 8px;
        /* Espaço entre o ícone e o texto */
    }
}

.form-subtitle {
    font-size: 1.2em;
    color: #333;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-family: "Roboto", sans-serif;
}

@media (max-height: 786px) {
    .hero-section_ebook {
        height: 151vh;
    }
}

/* Estilos para o formulário da primeira seção */
#hubspot-form-hero {
    width: 100%;
    max-width: 600px;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.actions {
    margin-top: 0 !important;
}