.contact-main {
  width: 100vw;
  background-color: #222324;
}

.basic-contact {
  margin: 0 5%;
  padding: 20px 0;
}

.contact-heading-div {
  text-align: center;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  color: #CCED31;
}

.contact-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 20px;
  font-family: "Google Sans Medium";
  color: #ffffff;
}

.contact-form-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  min-height: 600px;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px;
  border: 1px solid #383838;
  background: rgba(115, 115, 115, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.4px);
  -webkit-backdrop-filter: blur(7.4px);
  border: 1px solid #a5c50784;
}

.form-title {
  color: #CCED31;
  text-align: center;
  font-size: 3rem;
}

.form-group {
  margin-bottom: 15px;
}

form label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffffff;
}

form input,
form select,
form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #cccccc00;
  border-radius: 5px;
  font-size: 16px;
  background-color: #ffffff;
}

form button.form-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #CCED31;
  color: rgb(59, 59, 59);
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 50%;
  margin: 0 auto;
  display: block;
}

form button.form-button:hover {
  background-color: #87a103;
}

.thank-you-message {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: rgba(115, 115, 115, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.4px);
  -webkit-backdrop-filter: blur(7.4px);
  border: 1px solid #a5c50784;
  height: 200px;
}

.thank-you-message h2 {
  font-size: 24px;
  color: #ffffff;
}

.thank-you-message p {
  font-size: 18px;
  color: #ffffff;
}

.thank-you-message button.form-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #CCED31;
  color: black;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.thank-you-message button.form-button:hover {
  background-color: #a5c507;
}

.bullet-points {
  list-style-type: none;
  padding: 0;
  margin: 20px auto;
  text-align: center;
  color: #ffffff;
}

.bullet-points li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: "Google Sans Medium";
  display: block;
  text-align: left;
  width: auto;
}

.bullet-points li::before {
  content: '✔';
  position: absolute;
  left: 0px;
  color: #CCED31;
  font-size: 22px;
}

.contact-info {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #383838;
  background: rgba(115, 115, 115, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.4px);
  -webkit-backdrop-filter: blur(7.4px);
  border: 1px solid #a5c50784;
}

.contact-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 10px;
  gap: 30px;
}

.contact-info-left,
.contact-info-right {
  flex: 1;
  padding: 10px;
  background-color: #2e2e2e00;
  border-radius: 5px;
}

.contact-info-left {
  margin-right: 10px;
}

.contact-info-right {
  display: flex;
  flex-direction: column;
  max-width: 40%;
}

.contact-info-right iframe {
  flex-grow: 1;
  width: 100%;
  height: 250px;
  /* Ajuste a altura para mobile */
}

.contact-info-left h2,
.contact-info-right h2 {
  font-size: 24px;
  color: #CCED31;
  margin-bottom: 10px;
}

.contact-info-left p,
.contact-info-right p {
  font-size: 18px;
  color: #ffffff;
  line-height: 1.5;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 600px;
}

.loading img {
  width: 100px;
  height: 100px;
}

.hidden {
  display: none;
}

.placeholder {
  visibility: hidden;
}

.consent-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: flex-start;
  text-align: left;
}

.consent-container input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 2px solid #CCED31;
  border-radius: 4px;
}

.consent-container input[type="checkbox"]:checked {
  background-color: white;
}

.consent-container input[type="checkbox"]:checked::before {
  content: '✔';
  font-size: 18px;
  color: #CCED31;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.consent-text {
  font-size: 14px;
  color: #ffffff;
}

.consent-text a {
  color: #CCED31;
  text-decoration: underline;
  white-space: nowrap;
}

.recaptcha-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.width-team {
  width: 250px;
}

@media (max-width: 1380px) {
  .contact-heading-text {
    font-size: 50px;
  }
}

@media (max-width: 768px) {
  .contact-heading-text {
    font-size: 30px;
  }

  .contact-header-detail-text {
    font-size: 16px;
    text-align: center;
  }

  .contact-info {
    flex-direction: column;
  }

  .contact-info-left,
  .contact-info-right {
    width: 100%;
    margin-bottom: 20px;
  }

  .contact-box {
    margin: 0;
    gap: 20px;
    flex-direction: column;
  }

  .footer-section {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .contact-box {
    flex-direction: column;
    /* Empilhar elementos no mobile */
  }

  .contact-heading-text {
    font-size: 24px;
  }

  .contact-header-detail-text {
    font-size: 14px;
  }

  .form-title {
    font-size: 2rem;
  }

  form {
    padding: 10px;
  }

  .form-button {
    width: 100%;
  }

  .thank-you-message {
    padding: 10px;
  }

  .thank-you-message h2 {
    font-size: 20px;
  }

  .thank-you-message p {
    font-size: 16px
  }
  @media (max-width: 768px) {
    .contact-info-right {
      display: none; /* Esconde o mapa no mobile */
    }
    .width-team{
      width: auto;
    }
  }
  
}
.contact-form-section{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}