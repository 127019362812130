.project-card-div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.3s ease-out;
  text-decoration: none;
  z-index: 1;
}

.project-card-overlay {
  position: absolute;
  margin: -2rem;
  background: #fc1056;
  z-index: -1;
  transition: tranform 0.25s ease-out;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.project-card-div:hover .project-card-overlay {
  width: 100%;
  height: 100%;
  animation: 0.25s ease-out 0s 1 slideInFromLeft;
}

.project-card-div:hover .repo-name,
.project-card-div:hover .repo-description,
.project-card-div:hover .repo-details {
  transition-property: color;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 1.05s;
  color: #ffffff !important;
}

.project-card-div:hover .repo-live-btn,
.project-card-div:hover .repo-github-btn {
  transition: all 0.3s ease-out;
  background-color: white;
  color: black !important;
}

.feather-monitor,
.feather-github {
  stroke: #fff;
  margin-top: 5px;
}

.project-card-div:hover .feather-monitor,
.project-card-div:hover .feather-github {
  stroke: #000;
}

.repo-path-links {
  display: flex;
  padding: 2rem 0rem 0rem 0rem;
  width: 100%;
  justify-content: flex-end;
  gap: 1rem;
}

.repo-live-btn,
.repo-github-btn {
  display: flex;
  padding: 0px 1rem;
  font-size: 14px;
  font-family: "Google Sans Regular";
  border-radius: 20px;
  background: #fc1056;
  color: white;
  justify-content: center;
  align-items: center;
}

.repo-name-div {
  display: flex;
  align-items: center;
}

.repo-svg {
  margin-right: 0.5rem;
  min-width: 16px;
}

.repo-name {
  white-space: nowrap;
  line-height: 1.1;
  text-overflow: ellipsis;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  margin: 0px;
}

.repo-star-svg {
  margin-right: 0.3rem;
}

.repo-description {
  word-wrap: break-word;
  text-align: left;
  font-family: "Google Sans Regular";
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.repo-details {
  display: flex;
  flex-direction: row;
}

.repo-creation-date {
  margin: 0;
  font-family: "Google Sans Regular";
  font-weight: 400;
  margin-left: 0px;
  margin-right: auto;
}

@media (max-width: 768px) {
  .repo-name {
    font-size: 16px;
  }

  .repo-description {
    font-size: 14px;
  }

  .repo-live-btn,
  .repo-github-btn {
    font-size: 13px;
  }

  .repo-details {
    flex-direction: column;
  }

  .repo-creation-date {
    font-size: 14px;
  }
}
