.degree-card {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.card-title {
  font-family: "Google Sans Medium";
  font-size: 23px;
}

.card-subtitle {
  font-family: "Google Sans Medium";
  font-size: 18px;
  margin-top: 7px;
  margin-bottom: 5px;
}

.card-img {
  width: 200px;
  height: auto;
  border-radius: 50%;
}

.body-header {
  max-width: inherit;
  display: flex;
  border-radius: 7px 7px 0px 0px;
  padding: 10px;
}

.body-header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
}

.body-header-duration {
  width: 20%;
}

.duration {
  font-family: "Google Sans Regular";
  font-size: 16px;
  padding-right: 10px;
  float: right;
}

.body-content {
  max-width: inherit;
  border-radius: 0px 0px 7px 7px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.content-list {
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Google Sans Regular";
}

.card-body {
  border-bottom: 1px solid #d9dbdf;
  border-left: 1px solid #d9dbdf;
  border-right: 1px solid #d9dbdf;
  border-radius: 7px;
  margin: 10px;
  box-shadow: 5px 5px 5px #d9dbdf;
}

.visit-btn {
  margin: 0px 10px 10px 0px;
  padding: 10px 20px;
  border-radius: 40px;
  border: 0px;
  float: right;
  transition: all 0.3s ease-in-out;
}

.visit-btn:hover {
  box-shadow: 0 5px 15px #fc1056;
}

.btn {
  margin: 0px;
  padding: 0px;
  font-family: "Google Sans Regular";
}

@media (max-width: 768px) {
  .degree-card {
    flex-direction: column;
  }

  .body-header {
    flex-direction: column;
  }

  .body-header-title {
    width: 100%;
  }

  .body-header-duration {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .duration {
    padding: 0px;
    margin: 0px;
    float: left;
  }

  .card-img {
    width: 200px;
  }

  .card-body {
    width: 100% !important;
  }

  .card-title {
    font-size: 20px;
  }

  .card-subtitle {
    font-size: 16px;
  }
}
